@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f4f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.MuiButtonBase-root {
  border-radius: 5px;
}

.ql-editor {
  min-height: 200px;
  max-height: 600px;
  overflow-y: auto;
}

.ql-editor::-webkit-scrollbar {
  width: 8px;
}

.ql-editor::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

.ql-editor::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.ql-editor::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

p,
li {
  line-height: 1.6; /* Uniform line spacing */
  margin-bottom: 10px; /* Proper spacing between items */
}

ul,
ol {
  margin-top: 5px;
  margin-bottom: 10px;
  padding-left: 20px;
}

strong {
  display: block;
  margin-bottom: 5px;
}

.ql-editor {
  padding: 15px;
  max-width: 100%;
}

.ql-editor p {
  margin-bottom: 10px;
}

.ql-editor ul,
.ql-editor ol {
  padding-left: 24px;
}
